<template>
  <div class="index">
    <div class="headerLogo">
      <img src="../assets/img/indexlogo.png" alt="" height="100%">
    </div>
    <div class="search">
<!--      <div class="libraryAddress searchItem">-->
<!--        <el-select v-model="searchForm.StrOrganizationIDs[0]" placeholder="请选择" @change="selectchange()"  clearable>-->
<!--          <el-option-->
<!--            v-for="address in libraryAddresses"-->
<!--            :key="address.StrID"-->
<!--            :label="address.StrName"-->
<!--            :value="address.StrID">-->
<!--          </el-option>-->
<!--        </el-select>-->
<!--      </div>-->
      <!-- 书名 下拉选择-->
      <div class="searchSelect searchItem">
        <el-select v-model="searchForm.KeyName" placeholder="请选择" @change="selectKeyNamechange()">
          <el-option
            v-for="option in optionSelects"
            :key="option.index"
            :label="option.optionName"
            :value="option.index">
          </el-option>
        </el-select>
      </div>
      <div class="searchInput searchItem">
        <el-input v-model="searchForm.StrKeywords" placeholder="请输入关键词" @keydown.enter.native="getSearchBookListToBookList"></el-input>
      </div>
      <div class="searchButton searchItem">
        <el-button type="primary" @click="getSearchBookListToBookList">检索</el-button>
<!--        验收用-->
        <el-button type="primary" @click="advancedSearchBoxFun">高级检索</el-button>
      </div>
    </div>
    <!-- 高级检索条件  -->
    <div class="advancedSearchBox" v-show="this.advancedSearchBoxIsTrue">
      <el-card>
        <el-button @click="addDomain" class="addDomainButton">新增检索条件</el-button>
        <el-form :model="advancedSearchForm" ref="advancedSearchForm" label-width="100px">
          <el-form-item
            v-for="(item, index) in advancedSearchForm.items"
            :key="index">
            <div class="itemBox">
              <div class="itemBoxStyle">
                <el-select v-model="item.searchSelectDefaultValue" placeholder="书名" style="width:110px;" class="itemBoxEl">
                  <el-option v-for="option in optionSelects" :key="option.index" :label="option.optionName" :value="option.index"></el-option>
                </el-select>
                <el-input v-model="item.value" style="width:460px" class="itemBoxEl itemBoxInput"></el-input>
                <el-select v-model="item.searchSelectOrAndValue" class="itemBoxEl" style="width:110px;" >
                  <el-option v-for="option in searchSelectOrAnd" :key="option.index" :label="option.optionName" :value="option.index"></el-option>
                </el-select>
              </div>
              <el-button @click.prevent="removeDomain(item)">删除</el-button>
            </div>
          </el-form-item>
          <el-form-item>
            <span class="timeText">出版时间</span>
            <el-date-picker
              v-model="advancedSearchForm.StrPublicationDate[0]"
              type="year"
              placeholder="选择年" style="width:150px;">
            </el-date-picker>
            <span class="separator">-</span>
            <el-date-picker
              v-model="advancedSearchForm.StrPublicationDate[1]"
              type="year"
              placeholder="选择年" style="width:150px;">
            </el-date-picker>
            <!-- <el-date-picker
                v-model="advancedSearchForm.StrPublicationDate"
                type="monthrange"
                range-separator="-"
                start-placeholder="开始月份"
                end-placeholder="结束月份" class="timeSelectBox">
              </el-date-picker> -->
          </el-form-item>
          <el-form-item>
            <span class="timeText">分馆检索</span>
            <template>
              <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
              <div style="margin: 15px 0;"></div>
              <el-checkbox-group v-model="advancedSearchForm.StrOrganizationIDs" @change="handleCheckedLibraryChange">
                <el-checkbox v-for="library in libraryAddresses" :label="library.StrID" :key="library.StrID">{{library.StrName}}</el-checkbox>
              </el-checkbox-group>
            </template>
          </el-form-item>
          <el-form-item>
<!--            @click="getAdvancedSearchBookListToBookList"-->
            <el-button type="primary" >检索</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
</template>
<script>
import { getAllLibs } from '@/api'

export default {
  data () {
    return {
      libraryAddresses: [],
      optionSelects: [
        {
          index: 'all',
          optionName: '任意词'
        },
        {
          index: 'StrPositiveTitle',
          optionName: '书名'
        }, {
          index: 'StrFirstResponsible',
          optionName: '作者'
        }, {
          index: 'StrISBN',
          optionName: 'ISBN'
        }, {
          index: 'StrPublisher',
          optionName: '出版社'
        }],
      searchSelectOrAnd: [{
        index: 'or',
        optionName: '或(OR)'
      }, {
        index: 'and',
        optionName: '和(AND)'
      }],
      searchForm: {
        KeyName: '',
        StrKeywords: '',
        Sort: '-1',
        OrderBy: 'DTCreatedDate',
        OnLib: false,
        Distinct: true,
        Page: 1,
        PerPage: 10,
        StrOrganizationIDs: []
      },
      // 控制高级检索条件是否显示
      advancedSearchBoxIsTrue: false,
      advancedSearchForm: {
        items: [{
          value: '',
          searchSelectDefaultValue: '书名',
          searchSelectOrAndValue: 'or'
        }],
        StrOrganizationIDs: [],
        StrPublicationDate: [],
        Sort: '-1',
        OnLib: false,
        Distinct: true,
        OrderBy: 'DTCreatedDate',
        Page: 1,
        PerPage: 10
      },
      isIndeterminate: false,
      checkAll: false
    }
  },
  mounted () {
    // this.searchLibraryAddresses()
    this.searchForm.StrOrganizationIDs[0] = localStorage.getItem('library') == null ? '' : localStorage.getItem('library')
    this.searchForm.StrKeywords = sessionStorage.getItem('searchContent') == null ? '' : sessionStorage.getItem('searchContent')
    const keyname = sessionStorage.getItem('keyName')
    if (keyname !== null && keyname !== '') {
      this.searchForm.KeyName = keyname
    } else {
      this.searchForm.KeyName = 'all'
    }
  },
  methods: {
    // 判断是否为移动端
    isMobile () {
      return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    },
    // async searchLibraryAddresses () {
    //   // const { data: res } = await this.$http.get('search/list')
    //   // if (res.meta.status !== 200) {
    //   //   return this.$message.console.error(res.meta.msg)
    //   // }
    //   // console.log(res.data)
    //   // this.libraryAddresses = res.data
    //   const result = await getAllLibs()
    //   if (result.ok) {
    //     this.libraryAddresses = result.msg
    //   } else {
    //     return this.$message(result.msg)
    //   }
    // },
    advancedSearchBoxFun () {
      this.advancedSearchBoxIsTrue = !this.advancedSearchBoxIsTrue
    },
    // submitForm (formName) {
    //   this.$refs[formName].validate((valid) => {
    //     if (valid) {
    //       alert('submit!')
    //     } else {
    //       console.log('error submit!!')
    //       return false
    //     }
    //   })
    // },
    // resetForm (formName) {
    //   this.$refs[formName].resetFields()
    // },
    // selectchange () {
    //   localStorage.setItem('library', this.searchForm.StrOrganizationIDs[0])
    // },
    selectKeyNamechange () {

    },
    removeDomain (item) {
      var index = this.advancedSearchForm.items.indexOf(item)
      if (index !== -1) {
        this.advancedSearchForm.items.splice(index, 1)
      }
    },
    addDomain () {
      this.advancedSearchForm.items.push({
        value: '',
        searchSelectDefaultValue: '书名',
        searchSelectOrAndValue: 'or'
      })
      // console.log(this.advancedSearchForm)
    },
    handleCheckAllChange (checkAllValue) {
      if (checkAllValue) {
        for (var i = 0; i < this.libraryAddresses.length; i++) {
          this.advancedSearchForm.StrOrganizationIDs.push(this.libraryAddresses[i].StrID)
        }
      } else {
        this.advancedSearchForm.StrOrganizationIDs = []
      }
      this.isIndeterminate = false
    },
    handleCheckedLibraryChange (value) {
      // console.log(value)
      var checkedCount = value.length
      this.checkAll = checkedCount === this.libraryAddresses.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.libraryAddresses.length
    },
    getSearchBookListToBookList () {
      // 去除两端的空格，防止出现因为空格导致搜索不到的问题
      this.searchForm.StrKeywords = this.searchForm.StrKeywords.trim()
      const keyword = this.searchForm.StrKeywords
      if (keyword === '' || keyword == null) {
        alert('请输入搜索词')
        return
      }

      sessionStorage.setItem('keyName', this.searchForm.KeyName)
      sessionStorage.setItem('searchContent', this.searchForm.StrKeywords)
      this.$router.push({
        path: '/bookList',
        query: { res: JSON.stringify(this.searchForm) }
      })
    },
    getAdvancedSearchBookListToBookList () {
      var items = this.advancedSearchForm.items
      if (items.length === 0) {
        alert('请输入搜索词')
        return
      }
      var item0 = items[0]
      if (item0.value === '') {
        alert('请输入搜索词')
        return
      }

      // if (this.searchForm.StrKeywords === '') {
      //   alert('请输入搜索词')
      //   return
      // }
      this.$router.push({
        path: '/bookList',
        query: { res: JSON.stringify(this.advancedSearchForm) }
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .index{
    width: 100%;
    margin: 0 auto;
  }
  .headerLogo{
    margin-top: 80px;
    height: 100px;
    text-align: center;
  }
  .search {
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }
  .searchItem{
    margin: 0 8px;
  }
  .searchInput {
    width: 400px;
  }
  .el-select {
    width: 160px;
  }
  .advancedSearchBox {
    margin-top: 30px;
  }
  .itemBoxStyle{
    display: inline-block;
    width: 680px;
    border: 1px solid #cccccc;
    margin-right: 20px;
    height: 40px;
    line-height: 40px;
  }
  /* 利用穿透，设置input边框隐藏 */
  .itemBoxEl /deep/ .el-input__inner {
    border: none;
    height: 30px !important;
  }
  .itemBoxInput /deep/ .el-input__inner {
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    border-radius: 0;
  }
  .el-form-item{
    width: 960px;
    margin: 0 auto 20px auto;
  }
  .addDomainButton{
    margin-left: 140px;
    margin-bottom: 20px;
  }
  .timeText {
    margin-right: 20px;
  }
  .separator {
    margin: 0 10px 0 10px;
  }
</style>
