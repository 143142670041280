<template>
    <div id="maxBox">
      <div class="detailInfo">
        <el-card>
          <div class="backTop">
            <el-button @click="backToBookList">返回</el-button>
          </div>
          <div class="bookInfo">
            <div class="bookImg"><img :src="book.StrCoverURL" alt="" id="myImage"></div>
            <div class="info">
              <div class="bookTitle">{{book.StrPositiveTitle}}</div>
              <div class="infoTable">
                <div class="infoTableItem" v-if="book.StrISBN != ''">
                  <div class="infoTableItemTitle">国际标准书号(ISBN)：</div>
                  <div class="infoTableItemText">{{book.StrISBN}}</div>
                </div>
                <div class="infoTableItem" v-if="book.StrFirstResponsible != ''">
                  <div class="infoTableItemTitle">作者：</div>
                  <div class="infoTableItemText">{{book.StrFirstResponsible}}</div>
                </div>
                <div class="infoTableItem" v-if="book.StrPublisher != ''">
                  <div class="infoTableItemTitle">出版社：</div>
                  <div class="infoTableItemText">{{book.StrPublisher}}</div>
                </div>
                <div class="infoTableItem" v-if="book.StrPublicationDate != ''">
                  <div class="infoTableItemTitle">出版时间：</div>
                  <div class="infoTableItemText">{{book.StrPublicationDate}}</div>
                </div>
                <div class="infoTableItem" v-if="book.FloatPricePer != 0.0">
                  <div class="infoTableItemTitle">定价：</div>
                  <div class="infoTableItemText">{{book.FloatPricePer}}元</div>
                </div>
                <div class="infoTableItem" v-if="book.StrNumberPages != 0">
                  <div class="infoTableItemTitle">页数：</div>
                  <div class="infoTableItemText">{{book.StrNumberPages}}页</div>
                </div>
                <div class="infoTableItem" v-if="book.StrTypeName != ''">
                  <div class="infoTableItemTitle">分类号：</div>
                  <div class="infoTableItemText">{{book.StrTypeName}}</div>
                </div>
                <div class="infoTableItem" v-if="book.StrCallNumber != ''">
                  <div class="infoTableItemTitle">种次号：</div>
                  <div class="infoTableItemText">{{book.StrCallNumber}}</div>
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <div class="contentValidity" v-if="book.StrSummary != ''">
        <el-card>
          <div class="main_title">内容简介</div>
          <p style="text-indent:2em;">{{book.StrSummary}}</p>
        </el-card>
      </div>
      <div class="holdingInfo">
        <el-card>
          <div class="main_title">馆藏信息</div>
          <div class=""></div>
          <div class="holdingInfoTable">
            <el-table
              v-loading="loading0" element-loading-text="图书加载中..."
              :data="holdingInfoList"
              style="width: 100%">
              <el-table-column
                v-if="holdingInfoTableShowStrCallNumber"
                prop="StrCallNumber"
                label="种次号">
              </el-table-column>
              <el-table-column
                v-else
                prop="StrTypeName"
                label="索书号">
              </el-table-column>
              <el-table-column
                prop="StrBookBarcode"
                label="条形码">
              </el-table-column>
              <el-table-column
                prop="StrOrganizationName"
                label="所在馆">
              </el-table-column>
              <el-table-column label="馆藏状态">
                <template slot-scope="scope">
                  <span>{{scope.row.IsBorrowed ? '借出' : '在馆'}}</span>
                </template>
              </el-table-column>
              <el-table-column label="借出日期">
                <template slot-scope="scope">
                  <!-- 如果书籍已借出，显示借出日期，否则显示空或“无” -->
                  <span v-if="scope.row.IsBorrowed">{{getRandomBorrowDate()}}</span>
                  <span v-else>无</span>
                </template>
              </el-table-column>
              <el-table-column label="应还日期">
                <template slot-scope="scope">
                  <!-- 如果书籍已借出，显示应还日期，否则显示空或“无” -->
                  <span v-if="scope.row.IsBorrowed">{{getDueDate()}}</span>
                  <span v-else>无</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="IntBorrowedCount"
                label="借阅次数">
              </el-table-column>
              <el-table-column prop="StrBookshelfName" label="书架">
                <template slot-scope="scope">
                <span>{{scope.row.StrBookshelfName=='' ? '暂无信息' : scope.row.StrBookshelfName}}</span>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="StrBookshelfName" label="层架标">
                <template slot-scope="scope">
                <span>{{scope.row.StrBookshelfName=='' ? '暂无信息' : scope.row.StrBookshelfName}}</span>
                </template>
              </el-table-column> -->
            </el-table>
          </div>
        </el-card>
      </div>

      <!-- 书评 -->
      <div class="bookReview">
        <el-card>
          <div class="main_title">豆瓣书评</div>
          <!-- 先判断是否有评论，再执行 v-for 循环 -->
          <div v-if="comments.length > 0" class="comment-list">
            <div v-for="(comment, index) in comments" :key="index" class="comment-item">
              <div class="comment-header">
            <span class="comment-user">
              {{ comment.user }}
              <span class="star-rating">
                <!-- 动态生成星星 -->
                <i v-for="n in comment.rating" :key="n" class="el-icon-star-on"></i>
                <i v-for="n in (5 - comment.rating)" :key="'empty' + n" class="el-icon-star-off"></i>
              </span>
              <span class="comment-date">{{ comment.date }}</span>
            </span>
              </div>
              <div class="comment-content">{{ comment.content }}</div>
            </div>
          </div>
          <!-- 没有评论时显示暂无数据 -->
          <div v-else>暂无数据</div>
        </el-card>
      </div>

      <!-- 网络书店 -->
      <div class="bookStore">
        <el-card>
          <div class="main_title">网络书店</div>
          <div>
            <div class="bookBuyUrl">
              <a :href="dangdangUrl" target="_blank" >在当当中查看</a>
            </div>
            <div class="bookBuyUrl">
              <a :href="doubanUrl" target="_blank">在豆瓣中查看</a>
            </div>
          </div>
          </el-card>
      </div>
      <div class="authorOtherBooks">
        <el-card>
          <div class="main_title">作者其他图书</div>
          <div>
            <el-table
              v-loading="loading" element-loading-text="图书加载中..."
              :data="otherBooks"
              style="width: 100%">
              <el-table-column
                prop="StrPositiveTitle"
                label="书名">
              </el-table-column>
              <el-table-column
                prop="StrFirstResponsible"
                label="作者">
              </el-table-column>
              <el-table-column prop="StrPublisher" label="出版社">
                <template slot-scope="scope">
                  <span>
                    {{scope.row.StrPublisher=='' ? '暂无信息' : scope.row.StrPublisher}}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="StrPublicationDate"
                label="出版日期">
              </el-table-column>
              <el-table-column
                v-if="authorOtherBooksTableShowStrCallNumber"
                prop="StrCallNumber"
                label="种次号">
              </el-table-column>
              <el-table-column
                v-else
                prop="StrTypeName"
                label="索书号">
              </el-table-column>
              <el-table-column prop="StrBookshelfName" label="层架标">
                <template slot-scope="scope">
                  <span>
                    {{scope.row.StrBookshelfName=='' ? '暂无信息' : scope.row.StrBookshelfName}}
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-pagination  @size-change="handleSizeChange" @current-change="handleCurrentChange"
                         :current-page="searchOtherBooksForm.Page" :page-sizes="[10, 20, 30, 50]" :page-size="searchOtherBooksForm.PerPage"
                         hide-on-single-page layout="sizes, prev, pager, next, jumper" :total="totalBookNum">
          </el-pagination>
          </el-card>
      </div>
    </div>
</template>

<script>
import { getBooksByConditions, getAuthorOtherBooks } from '@/api'
export default {
  name: 'detailInfo',
  data: function () {
    return {
      // 静态的评论数据
      commentsForBook1: [
        {
          user: '苏煜月',
          date: ' 2016-05-25 17:26:15',
          content: '“无数怀着喜悦心情观看烟花的人，都不知道，那个小小孩童，喜欢的不是燃烧的炽热，而是烟花散尽的凄凉。”',
          rating: 4
        },
        {
          user: '畸零人',
          date: '2011-03-16 22:43:00',
          content: '这种词传太主观，实在没法喜欢',
          rating: 3
        },
        {
          user: '欢姐睇住我',
          date: ' 2011-03-27 11:31:20',
          content: '奈何写传的人太肉麻了。。',
          rating: 3
        },
        {
          user: '小水',
          date: '2010-01-19 12:04:01',
          content: '很喜欢这本书的整体编排结构：大致按照时间顺序，以容若生平中的重要场景和重要人物串成了一个生动丰满的人物传记故事。 但是对作者的文笔，实在不敢恭维，仿古风格中又夹杂着一些现代词语，明明是在刻画清末的那种带有历史韵味的氛围，却常常加入一些时尚的元素',
          rating: 3
        },
        {
          user: '长青',
          date: '2012-05-03 15:46:49',
          content: '非关癖爱轻模样，冷处偏佳。 别有根芽，不是人间富贵花。 谢娘别后谁能借，漂泊天涯。 寒月悲茄，万里西风瀚海沙。',
          rating: 3
        },
        {
          user: '第五象限 ',
          date: '2010-07-15 23:47:47',
          content: '淡若游丝的悲伤却透着点滴无可奈何的亲切，自古才子佳人多郁郁寡欢，因为满腹经纶、才华横溢，世人习惯于仰望，却也被动地成了似扎不进土壤的花儿… 　　人总在追寻着属自己的目标，每个执着坚持的人都值得尊重',
          rating: 3
        },
        {
          user: 'Sisi',
          date: '2012-08-26 20:14:21',
          content: '相望相思不相亲',
          rating: 3
        },
        {
          user: '美蝶',
          date: '2011-02-05 14:29:20',
          content: '性情中人，我喜欢',
          rating: 3
        }
      ],
      commentsForBook2: [
        {
          user: '千万只鸟',
          date: '2024-07-04 18:36:10',
          content: '《昆虫记》的世界并非是冷冰冰地人类观察，读完这本书，我想最重要的是，我们看见了真正的“热爱”与“尊重”，一个贴近大地的人，一个深深热爱自己一生生物事业的人，包含着温情和好奇的人，带我们走进一场奇妙的观察之旅。 以前我们常常讨论，要人与自然和谐相处，如果这句话只是一句口号，读完这本书或许你就能明白这句话的真正价值和意义。法布尔用三十年写就的观察手记，语言绝不会晦涩，相信你，小读者们一定也会被深深地吸引、打动。',
          rating: 5
        },
        {
          user: '女孩和三文鱼',
          date: '2024-04-20 10:01:20 ',
          content: '小时候错过的好书，私心觉得更适合逐渐丧失好奇心的大人看。',
          rating: 4
        },
        {
          user: '曾跃楼',
          date: ' 2024-02-09 20:26:20 ',
          content: '一万颗星。打开了世界的大门！',
          rating: 5
        },
        {
          user: '开心',
          date: '2024-06-09 04:38:49',
          content: '趣味横生。',
          rating: 5
        },
        {
          user: '007',
          date: '  2024-07-25 07:59:31',
          content: '作者用人性去看待昆虫，用人类世俗理解昆虫的“婚丧嫁娶”。字里行间流露出对生命与生活的尊敬。',
          rating: 4
        },
        {
          user: '阿树。',
          date: ' 2024-04-28 13:25:30 ',
          content: '“用自己喜欢的方式过一生”作者有一整个旷野和庄园。“俯仰之间，以为陈迹”每个人心里都希望有这样一个百草园吧。',
          rating: 4
        },
        {
          user: 'There~y察',
          date: '2024-07-14 20:27:37 ',
          content: '不是这个版本的，但当时读的时间与此书出版时间差不多',
          rating: 4
        },
        {
          user: '喵喵机',
          date: '2024-09-04 00:40:23',
          content: '童年回忆',
          rating: 4
        },
        {
          user: '礼物大王叶轻眉',
          date: '2024-11-14 23:40:39',
          content: '在昆虫中长大的童年，知了和蚂蚁带来的快乐是一般人想不到的。',
          rating: 4
        }
      ],
      commentsForBook3: [
        {
          user: '小星星',
          date: '2013-05-06 22:59:14',
          content: '像听老先生用地道京片子唠嗑似的，那样悠闲、口语化却又深刻，哀而不伤。我愿意用弗洛伊德的理论来分析一切，认为一个人的性格百分之九十大几都是由后天环境决定的，还相信性格决定命运。可是在祥子面前，那套理论却不知道该怎么使了：在那样残酷的社会中，性格好被糟蹋、性格坏了反倒能活下去——我该怎么解释呢？只能替他难过。有人评论这部作品时嫌作者没有把主题上升到“所有无产者联合起来闹革命”的高度，可是我却想，老舍从来都是远离政治的人，他写作只是为了单纯写出他内心想表达的——京味儿、下层人民、残酷世道、命运捉弄……——请别用“革命文学”那套标准来评判一切好吗！！！',
          rating: 5
        },
        {
          user: '大-燕-威-王',
          date: '2012-01-05 01:21:43',
          content: '其实至今仍有许多人，像祥子一样活着而找不到出路。就那么活着，不思考人生意义，才能轻松地做牛做马。这种苦闷在毛姆《人生的枷锁》中也能看到，但不同的是，毛姆为他的主人公找到了心灵上的出路。',
          rating: 4
        },
        {
          user: '三七莲华  ',
          date: '2008-06-06 23:47:33',
          content: '我相当相当喜欢老舍，觉得他是最棒的作家',
          rating: 4
        },
        {
          user: '木上升 ',
          date: ' 2016-01-30 13:53:17',
          content: '祥子毁在他的经验主义，还有点直男癌，虎妞没那么坏反而是有手段有胆识的，结尾硬往个人主义上扯反而突兀又不合理。不过我喜欢老舍的文风',
          rating: 4
        },
        {
          user: 'Chwan  ',
          date: '2011-07-16 21:40:31',
          content: '一场春雨后，穷人病死无数，诗人作诗人却诗兴大发。',
          rating: 3
        },
        {
          user: '鸳鸯  ',
          date: ' 2008-08-18 12:10:07',
          content: '深夜。一点到六点。不能停止，通宵读完。',
          rating: 5
        },
        {
          user: '泽帆 ',
          date: ' 2010-04-22 19:29:05',
          content: '祥子比福贵还让我动容。',
          rating: 5
        },
        {
          user: '我叫卢大胆',
          date: '2019-04-19 09:39:25',
          content: '人把自己从野兽中提拔出来，可是现在人还是把自己的同类驱逐到野兽中去',
          rating: 5
        }
      ],
      commentsForBook4: [
        {
          user: 'Chloe',
          date: '2015-11-20 11:45:46',
          content: '充满想象力的作品！想象力比什么都重要，人真的应当在年轻时读一读这样宽广的作品，打开眼界和头脑。',
          rating: 5
        },
        {
          user: '夜寻',
          date: '2011-10-30 14:41:00',
          content: '仔细想来，这是我买的第一本科幻，当时买的是一本口袋书，那个时候还是小学，一点科幻的概念都没有。这本书给我打开了一个新世界啊。',
          rating: 5
        },
        {
          user: 'Περσεφόνη ',
          date: ' 2018-12-29 23:36:31',
          content: '毫无疑问 凡尔纳是最棒的科幻小说家',
          rating: 3
        },
        {
          user: '胡胡：做老农',
          date: ' 2010-06-12 09:56:19',
          content: '在那个年代，这本书相当厉害了。而且书里的很多想象现在已经实现。',
          rating: 4
        },
        {
          user: '大-燕-威-王',
          date: '2010-01-24 15:54:54',
          content: '凡尔纳的作品俺最喜欢这本了！！',
          rating: 3
        },
        {
          user: '小木 ',
          date: '2023-12-11 14:33:54',
          content: '经典从来是不分年龄的，一直期待和孩子一起读书，终于实现了。 这本书让我们得以窥见海底的丰富、瑰丽和神奇，海底森林、海底打猎、海底城市废墟、海底珊瑚…还有那鲸鱼群、海底隧道都令人向往和惊叹，尼摩船长的生活也令人着迷不已。 在繁杂的生活之余，读这本书让人安静，希望自己的孩子热爱自然，也能产生爱护自然之心。',
          rating: 4
        },
        {
          user: '琼斯黄',
          date: '2006-06-03 09:13:02',
          content: '建筑了我儿时的梦想。',
          rating: 4
        },
        {
          user: 'Moss大妖 ',
          date: ' 2009-05-29 22:29:54',
          content: '超群的想象力，即使在今天看来仍然使人为之惊叹。',
          rating: 4
        },
        {
          user: '暮云平ym',
          date: '2024-11-15 15:00:35 ',
          content: '海底2万里，读了20年。年少时觉得无聊的书，稍长一些，却佩服于人类的想象力和好奇心。如果在年少时读完，也许不一定可以读懂，但肯定会对海洋有更多的好奇心吧。',
          rating: 5
        },
        {
          user: '梅菜扣肉  ',
          date: '2024-11-13 21:37:41',
          content: '船长是有所为有所不为的，这也正是本书打动人心的地方',
          rating: 3
        },
        {
          user: 'Ocap',
          date: '2015-06-10 19:12:46',
          content: '入门书目含羞补看计划。一直觉得想象的最高境界不过于最令人感到真实的虚构，凡尔纳以幻想为基、用科学凭据铺路的创作与我内心的渴求不谋而合。很多背景设置，都意在将读者从现有的真实，引向未被发掘的真实，甚至这种真实还不是在未来，而就在我们此时此刻的眼界盲区之中。',
          rating: 3
        },
        {
          user: '泛流',
          date: '2016-06-27 21:00:49',
          content: '纯粹是为了了解科幻史才读的，之前读过船长的儿女，所以对这一题材有些厌倦，跳过了很多我不不感兴趣的生物学知识。有种说法是说，好的科幻作品不会随着时间的流逝而丧失其科幻性，这本书是往日的科幻小说，但是却是今日的探险小说，更适合青少年阅读。本书最大意义在于其描写了后大航海时代人类对于未知领域的探索精神，这也与现在流行的太空科幻精神相契合，新的征程，不变的精神。',
          rating: 5
        }
      ],
      commentsForBook5: [
        {
          user: 'kento ',
          date: '2015-10-16 10:14:00',
          content: '文字非常美。我想再重读几遍，尤其描写景物那几篇，读起来是一种享受。',
          rating: 4
        },
        {
          user: 'godeau ',
          date: ' 2011-01-30 01:22:28',
          content: '现在再看就觉得不舒服了',
          rating: 4
        },
        {
          user: '丛昕  ',
          date: '2012-05-24 18:21:31',
          content: '读朱自清的散文，可静心，可怡情……',
          rating: 5
        },
        {
          user: '明月 花枝 你  ',
          date: '2022-07-14 14:41:11',
          content: '他的文字，以前我觉得难懂，但是觉得美得像月下轻纱。毕竟是白话文的初始阶段，现在读来难免会有些不适应，不过这也算是陌生化的美感',
          rating: 5
        },
        {
          user: 'Stardust  ',
          date: '2014-10-29 20:21:00',
          content: '先生的匆匆小学记到现在。。。却还是让时间匆匆去了。。。',
          rating: 5
        },
        {
          user: '小千秋',
          date: '  2021-09-15 16:56:26',
          content: '说理议论类文章读起来，很有些周作人文学批评里宽容平和之味，但私以为要多些“尖刻”才好，不过也许秋实原本就是温厚儒雅的人，是爽利不得的。《儿女》《背影》《论亡妇》《择偶记》四篇展示出一种相当普遍的人生，余味绵长。特别的是，朱自清的写景散文里不唯有秦淮河和扬州，松堂、白马湖亦是有的，可我却只在前处觉察到一种幻灭的情思。',
          rating: 5
        },
        {
          user: '十月转动十月 ',
          date: ' 2024-03-02 22:51:19',
          content: '当时看觉得酸腐，现在看真是美丽且真诚。但杂文确实比较落俗且啰嗦',
          rating: 5
        }
      ],
      commentsForBook6: [
        {
          user: ' sycamoretrees  ',
          date: '2016-03-28 10:59:54',
          content: '没有一句废话！！！看似东拉西扯的叙述视角，竟然恰如其分，不至于看到这儿忘了那儿，他会悄然声息地提醒你，我讲到这儿了噢，这个事儿我之前有浅显提过的哦，想起来了吧！我现在要铺陈开来讲了哦！那种感觉！厉害！！！',
          rating: 4
        },
        {
          user: ' 十一月的雨  ',
          date: '2014-12-25 11:38:01',
          content: '重温《在细雨中呼喊》，依旧是最喜欢的余华小说。初读时莫可名状的牛逼感这次读时更清晰了一些。不同于《活着》和《许三观卖血记》，本部的第一人称回忆视角让人更有代入感。余华对极端环境下人性的洞见是极深刻的，但他又不是冷酷的剖析或者揭示。他从苦难或无常中升华出的荒诞或幽默，流露出的是悲悯。',
          rating: 5
        },
        {
          user: '四月。  ',
          date: ' 2013-10-30 11:59:24',
          content: '我看《活着》看哭了，可心里满满的都是温情；看《细雨》看笑了，笑过之后却满心悲戚。',
          rating: 4
        },
        {
          user: '玉手竹子  ',
          date: ' 2015-08-19 16:00:41',
          content: '拼图式的小说架构，在不断的插叙过程中，以自传式的笔触详写了农村少年在家庭中及在被寄养时的童年经历。以树干为主体延伸出其细枝末节的人生百态——父与子的冲突，孩童与成人的世界的交接，性的萌发与破灭......再佐以充满黑色幽默的笔触，使得余华这部长篇虽老朽沉重但又精彩纷呈。',
          rating: 5
        },
        {
          user: '胖达叔   ',
          date: '2016-08-04 12:07:08',
          content: '看得又哭又笑。像通宵聊天，听好友有一茬没一茬地说自己的过去，看着他偶尔发愣的眼神和时而低垂的睫毛。',
          rating: 5
        },
        {
          user: '风高路斜 ',
          date: '  2014-09-02 16:34:30',
          content: '这本书是每一个人童年记忆的药引，引起童年各色情绪的浩荡奔涌',
          rating: 5
        },
        {
          user: ' zz夭夭 ',
          date: ' 2013-12-08 18:54:57',
          content: '读余华的小说总是有一种想一口气读完的感觉。之前看活着，看许三观都是这种感觉。孙家的命运不知道有多少是余华的亲历，但不管如何，都是一种细致入微的展现，虽然最后很多人的命运都难逃一死，但死或许就是那个时代，在农村，最普遍的现象。',
          rating: 5
        },
        {
          user: '爱上吃包子   ',
          date: '2021-05-24 09:40:09',
          content: '这或许不是余华评价最高的一本书，但却是我私心最爱的一本书。童年对性懵懂的罪恶感与好奇心的交织，父辈至高无上的权力随时间而走向万劫不复的崩坏，师长在那小小的一方空间里用仅有的威严作恶。我喜欢这样淡淡的文字，仿佛细雨中的呢喃，对你娓娓道来岁月。这里没有大奸大恶，有的只是生活的无奈和一个个由于不经意而伤害的瞬间。而岁月却不管这些，没有人会在意你心里的隐痛，它像个无情的碾子，又像条奔腾不息的河流，从一个个鲜活的生命上面碾压流淌过去，而未曾稍作停息。在时间长河中行走的我们，也逐渐被生活打磨成了粗粝，从自己，抑或他人的身上，碾过去，又碾过去了。',
          rating: 4
        },
        {
          user: '卡珊德拉 ',
          date: '  2015-02-03 16:26:11',
          content: '非线性叙事，由孙光林零星片段的回忆组成，他千方百计试图去理解自己不正常的童年生活，不忠的丈夫在妻子坟前哭泣，儿子看到虚弱不堪的母亲被野狗吃掉，那个笑着像死人、吃饭就活过来的祖父，穿着一身黑衣终日躲在房里、与一墙的黑白照聊天的孤独的老太太....我们不是活在土地上，而是活在时间里，在细雨里呼喊，就像祖父去世前微微张开的嘴，他的灵魂像小鸟一样从张开的嘴飞了出去，此刻在稻田的上空盘旋，这是对命运的叩问，也是将死之人的眼睛穿过阳光，成为死亡的歌唱家。',
          rating: 5
        },
        {
          user: '不驯的羔羊   ',
          date: '2016-07-05 22:30:35',
          content: '这本书写出的大部分东西都恰好与昆德拉说的“媚俗”（或译“刻奇”）构成“反义”关系。亲人间的自私、冷酷、残忍、薄情；成长历程的“惊险”，无知中对青春期自我变化的恐惧、焦虑、痛苦；不平等背景下的敏感自尊、虚荣心；小孩子幼稚的世故与势利等等。这些故事都真实得不留情面、真实得令人发窘、真实得令不愿私下“媚俗”的温情假面的人难以忍受。',
          rating: 4
        }
      ],
      commentsForBook7: [
        {
          user: '马特 ',
          date: ' 2017-08-24 02:12:52',
          content: '用科幻的小刀，雕琢人性之光。',
          rating: 4
        },
        {
          user: '小猴 ',
          date: ' 2016-12-31 20:43:48',
          content: '大刘真牛，科幻小说竟让我看得直感动π_π',
          rating: 5
        },
        {
          user: 'azurebleu ',
          date: ' 2019-02-05 20:19:57',
          content: '看了四篇我才发现这是一部短篇小说集。[微笑]',
          rating: 4
        },
        {
          user: '哎呀 ',
          date: ' 2019-01-27 12:48:17',
          content: '最后一部分读的鸡皮疙瘩此起彼伏，硬科幻中的散文诗。故事其实挺平淡的，但是读的一直很想哭。今天我第一次在思考，诗意居然是可以脱离文字的美感而直接存在的，这又是为什么呢？',
          rating: 4
        },
        {
          user: 'Kelvin ',
          date: ' 2019-02-02 00:37:53',
          content: '宏观上理性冷血，微观上悲天悯人，大刘的短篇都很有趣。',
          rating: 5
        },
        {
          user: 'lord Cliff',
          date: '2019-01-17 16:05:27',
          content: '那种悲观的诗意是大刘的科幻最独特的美',
          rating: 5
        },
        {
          user: '🍃 ',
          date: ' 2019-01-30 21:54:49',
          content: '永远孤独地封闭于地心中的落日六号。科幻也是有情感的。',
          rating: 4
        },
        {
          user: '游酒 ',
          date: ' 2018-12-20 23:50:29',
          content: '回过头来看还是第一篇比较浪漫 最有趣应该是微纪元吧',
          rating: 5
        }
      ],
      commentsForBook8: [
        {
          user: 'valian   ',
          date: '2023-11-29 00:51:50 ',
          content: '莫言的剧本选择颠覆这个家喻户晓的英雄故事，戏中戏的结构从一开始就充满讽刺，当古代侠义事迹变成一场关于“出名”的游戏，随着这个事件一同被消解掉，还有关于英雄的所有想象。 莫言在这个故事中，从一开始就采用了非常现代化的戏剧语言，因此这个故事与其说是“篡改”历史，不如说是借由古代这个道德高尚的故事拷问现在，拷问在这个物欲膨胀的时代和社会中，我们为人处世的动机，我们对自身的安放。 当英雄变成小丑，高义道德变为戏谑人生，闹剧被载入史册，我们都一样丑陋、一样普通，也一样高尚',
          rating: 4
        },
        {
          user: '嗨踢踢  ',
          date: ' 2023-11-10 20:38:19  ',
          content: '我深刻怀疑emo拍《满江红》之前读了这个剧本！大段台词很有莫言风采，实排出来肯定好看',
          rating: 5
        },
        {
          user: '胡典  ',
          date: ' 2024-03-19 16:14:24 ',
          content: '感觉是莫言小说转型话剧的这几部话剧里边儿比较出彩的一部，将现代思想赋予人物，最后的结尾感觉被张艺谋学习了拍电影《满江红》结尾了，因此结尾的时候有一些失望。这部《我们的荆轲》，立意很好，尊重历史的情况下又掺入了一些架空的东西，反而使得整个故事和人物动机更加的丰富，人物塑造的立体生动，狗屠和秦舞阳两个逗比，哲学家高渐离，侠士荆轲，尤其是燕姬和荆轲对话那几幕，直接将剧本上升了一个层次。在莫言的笔下，女性总是最清醒，最伟大的。荆轲为什么要刺秦？他所追寻的高人在哪里？不在彼岸，也不在此岸，就在他的心中。是为了人民吗？为了正义吗？为了报恩，还是为了公道，为了侠客之名？或许是为了千古流芳？都不是，正如莫言所说的，刺情根本就没有目的，自然也没有意义，“我们都是荆轲”！',
          rating: 4
        },
        {
          user: '信然  ',
          date: ' 2023-11-13 14:59:26 ',
          content: '“被刺者的身份越高，刺客的名声越大；行刺的环境越险恶，刺客的声誉越隆。” ——还不错，对原故事太熟悉了，所以惊喜不多。',
          rating: 5
        },
        {
          user: '破碎的天空free ',
          date: '  2023-10-21 18:38:08',
          content: '这要是再出就是近期的第四个版本了，手里已经有之前莫言典藏大系平装精装三个版本了',
          rating: 5
        },
        {
          user: '你吃小蛋糕吗 ',
          date: ' 2024-01-20 23:29:35',
          content: '妙啊，替身是神来之笔，张艺谋绝对读过。',
          rating: 4
        },
        {
          user: '揚一  ',
          date: ' 2024-02-19 11:37:16 ',
          content: '很好看。莫言老师最后关于这部话剧的几次分享更精彩。 「谁说最清醒的人，就一定能够免俗呢？」 「我们每个人都有这样的处境，我们知道路在何方，但我们不敢去走。」',
          rating: 4
        },
        {
          user: '乱七八糟的小花  ',
          date: ' 2024-08-15 17:00:56',
          content: '在抖音上刷到了燕姬和荆轲的对话去看的，居然是莫言写的剧本，我真的是孤陋寡闻了。 很喜欢里面的对话和立意，借古讽今。',
          rating: 5
        },
        {
          user: 'SASUKEㄥ广庭  ',
          date: '  2024-06-02 12:19:09',
          content: '眾所皆知的故事，寫出了不一樣的味道。',
          rating: 4
        },
        {
          user: 'kei  ',
          date: ' 2024-06-25 21:18:00  ',
          content: '故事本来想打三星！但燕姬和荆轲那段对话，太精彩了！',
          rating: 5
        },
        {
          user: '宁涣元 ',
          date: '  2024-04-10 23:58:30 ',
          content: '天呐，这是我读的第二本莫言，第一本是《鳄鱼》，但我个人更喜欢这本更多一点，没想到莫言居然已到达 next level了！',
          rating: 4
        },
        {
          user: ' Winds Song   ',
          date: '2024-02-19 00:09:04 ',
          content: '历史的荆轲是英勇无惧的刺客，是大义凛然的壮士，但我们的荆轲是有血有肉、想过退缩、有过迟疑的普通人。通过对刺秦动机的不断追问来重新解构这个家喻户晓的故事，让刺秦不再是一件理所当然的必然正确的事情，从而为读者留下更多解读和想象空间。燕姬和影子秦王的介入也让剧本也多了点意外之喜。',
          rating: 5
        }
      ],
      commentsForBook9: [
        {
          user: ' 回回馆の巴图鲁   ',
          date: ' 2014-07-23 21:43:43',
          content: '这书我看得特慢，只要出现一个地名我就想在谷歌地形图上给找出来，然后细细从战略层面揣摩用兵之道。特别是街亭之战，光在地图上找到街亭这个屯兵的小谷道就花了不少功夫，刚开始始终想不明白这个离汉中十万八千里的地方到底哪儿重要，后来研究了下才知道古时天水通长安的道路并非沿渭水河谷顺流而下，而是走官陇古道，街亭正处于古道的咽喉之处，蜀丢街亭就意味着把背后留给了魏国，腹背受敌，命都难保。总之这本书值得对着地形图细细揣摩，尤其是诸葛亮六出祁山那段。',
          rating: 4
        },
        {
          user: ' 文泽尔   ',
          date: ' 2007-09-18 21:49:54',
          content: '“书生轻议冢中人，冢中笑尔书生气！”',
          rating: 5
        },
        {
          user: ' 芦哲峰   ',
          date: ' 2007-11-23 14:47:17',
          content: '通篇都是权谋，文争武斗。其中，最有心计、最会骗人者，称王称圣称君主；最有武功、最会杀人者，称侠称将称英雄。尔虞我诈、杀人如麻都不算事，只要贴上某一种道德标签，比如“忠”，比如“义”，不论它是如何的卑鄙与残忍，都可以变得正大光明，足以垂范后世。历史不过如此。',
          rating: 5
        },
        {
          user: ' 不要焦虑浣熊妹   ',
          date: ' 2011-03-24 01:32:03',
          content: '看的是再版的，好惭愧啊应该是小学时候就读的书。',
          rating: 4
        },
        {
          user: 'justinyang94     ',
          date: '2016-12-25 00:01:03',
          content: '大概每一个认真的成年人，都会用三五年读完历史沉淀下来的经典，用好多年去读小众且塑造个性的书籍，再用余生回到经典……',
          rating: 5
        },
        {
          user: '陶者无缰    ',
          date: ' 2011-05-26 03:40:10',
          content: '“流星的光芒虽短促，但天上还有什么星能比它更灿烂，辉煌？ 当流星出现的时候，就算是永恒不变的星座，也夺不去它的光芒。” ——三国，周郎，赤壁。',
          rating: 4
        },
        {
          user: '匿     ',
          date: '2014-03-13 16:45:22',
          content: '奉孝延寿一年，演义至六十回足矣。',
          rating: 5
        },
        {
          user: '神威     ',
          date: '2011-12-03 18:29:08',
          content: '无论什么时候看，都是经典',
          rating: 5
        }
      ],
      commentsForBook10: [
        {
          user: '花絮袭人  ',
          date: '2007-10-23 23:16:01',
          content: '把这书列为四大名著之一,说明我们民族还是有眼光的.',
          rating: 4
        },
        {
          user: '  一只麦麦   ',
          date: ' 2010-02-05 10:03:56',
          content: '“老不看三国，少不看水浒。男不看西游，女不看红楼。”扯淡阿...趁年轻到来之前没读过才是悲哀的呢。经典永远值得回味',
          rating: 5
        },
        {
          user: '大島     ',
          date: ' 2015-11-08 11:12:19',
          content: '可与《红楼梦》并肩的文学大成之作。解读不知道多到哪里去了，作者是吴承恩还是丘处机还是其他人到现在也没到底搞清楚，但是实实在在的文字，让人忍不住喜欢，越读越会发现很多文学文字的乏力。',
          rating: 4
        },
        {
          user: '荒野之息史瑞克',
          date: '2013-11-05 23:57:46',
          content: '这才是真正的公路小说好吗！第一次读，真是太好看了，比冰与火之歌都好看！看完对佛教产生很大兴趣。不过我觉得书名字该改成《猴王大闹动物园》。',
          rating: 3
        },
        {
          user: '伊兰月    ',
          date: '  2019-10-14 14:09:06',
          content: '看过原著后发现师徒四人的感情并没有电视剧演得那么好，孙悟空真的是特别优秀的徒弟，典型的刀子嘴豆腐心，打妖是他化缘是他救唐僧也是他，他行动力强责任心重，给人十足的安全感，而他得到的是唐僧的一次次质疑，身为高僧的唐三藏对谁都可以宽容，唯独对孙悟空有错必究，被冤枉、质疑的悟空却没真心责怪过这个师傅，论修行我真的觉得孙悟空比唐僧高多了。',
          rating: 5
        },
        {
          user: '天亮天黑     ',
          date: ' 2010-03-23 16:37:40',
          content: '生命是场漫长艰辛的修行，但即便如唐僧般软弱，也可以到达终点。',
          rating: 5
        },
        {
          user: 'Zhuo     ',
          date: ' 2015-08-09 17:01:46',
          content: '重读西游记，真的是太太太好看了！小时候读这本书只看降妖除魔，现在反而不在乎什么妖怪什么神通，发现了之前忽略掉的不少段子与人情世故。',
          rating: 4
        },
        {
          user: '大眼袋儿姨娘     ',
          date: ' 2012-06-03 22:37:57',
          content: '去掉你的棱角，把你变成佛，杯具故事啊！',
          rating: 5
        },
        {
          user: '0000    ',
          date: '  2013-08-18 18:13:19',
          content: '经典，不多说。读罢，坚信真正的取经者为悟空，其它人皆为陪衬。',
          rating: 4
        },
        {
          user: '瓦达西瓦又又又桑只爱这巧克力     ',
          date: ' 2013-12-27 14:47:03',
          content: '心猿归正，人情文章。凡是有后台的妖怪都被接走了，没后台的都被一棒子打死了。“只要你见性志诚，念念回首处，即是灵山。”“若将容易得，便作等闲看。”“你去乾坤四海问一问，我是历代驰名第一妖！”“好汉吃我老孙这一棒！”“‘姐姐，我们洗了澡，来蒸那胖和尚吃去。’行者暗笑道：‘这怪物好没算计！煮还省些柴，怎么转要蒸了吃！’”如来二徒金蝉子，不知首徒却是谁？',
          rating: 5
        }
      ],
      commentsForBook11: [
        {
          user: '朱红尽颓   ',
          date: ' 2012-05-15 17:26:24',
          content: '盛衰之理，本为天命。然而人心就是如此。眼见得他起高楼，于是便不忍心见他楼塌了。见过他鼎盛的时候，再看他的衰败就无比心酸。而更加可悲的是，目睹这场哗变的你，本就是这戏中之人。',
          rating: 4
        },
        {
          user: 'fwb  ',
          date: '  2007-01-15 12:55:56',
          content: '第一次看《红楼梦》，是小学五年级。因所有人都说，这是部名著，于是，期末考后，怀着极大的敬意与耐心，准备好好下番苦功夫。不曾想，从头到尾，一点不打绊儿地，没花多长时间，就囫囵吞枣地顺利看完，幼小心灵还因此生出些疑问：怎么一点儿不象名著呀，即不难懂，也不深刻，不就是些家长里短的故事集锦吗！ 留下印象的，不是爱情纠葛，不是阶级矛盾，不是白茫茫一片真干净，而是当时粗糙日常生活中，一些根本无法想象的精美事物：比如，一些菜品，要经过如此多复杂细致的步骤，才能一点点做出；比如，一些庭院宅子，院名的起法，树木的搭配，房子的色彩，有那么多讲究；比如，一些好听的布料名，一些古怪材质的饰品，一些用途不明的摆设；再比如，闲谈，对诗，饮茶……才知道，原来人可以这样生活，可以这样打发时间，而不被视为浪费生命，也无需心下内疚，有些无法理解，又有点儿想入非非。 这或许是文学的另一个用处，让人从踏实细节处，活生生触摸到一个时代的体温。只是后来，每当我跟人说起，《红楼梦》对我来说，就是故事会，就是菜谱与家居指南时，十个会有九个半露出惊讶表情，让我觉得自己，可真没文化！',
          rating: 4
        },
        {
          user: '素梳影   ',
          date: ' 2012-08-12 21:30:00',
          content: '亲戚是干什么用的大全',
          rating: 4
        },
        {
          user: '时间之葬   ',
          date: ' 2013-12-14 22:31:46',
          content: '长这么大，到今天才把这部奇书读完。觉得并没有那么多微言大义，倒是曹雪芹的文笔实在了得，对人的那些细腻情感拿捏得十分到位。特别想说的一点是，坚决认为林黛玉不能算是什么完美的理想主义者，她非但不完美，而且是毛病最大的一个。所有的问题又都归结于三个字——看不开！',
          rating: 5
        },
        {
          user: 'NGC2906大使   ',
          date: ' 2016-03-03 15:46:42',
          content: '我觉得史湘云是最真的一个女子 黛玉 你就天天伤心吧。宝玉 你就天天游走在各个姐姐之间吧。宝钗 你就世故圆滑你的吧。唯独我的湘云手帕包花作枕醉卧青石凳，蝶舞翩翩。唯独我的湘云真真的直言快语管你是太太还是姊妹。唯独我的湘云妙语连珠诗词连篇造就“寒塘度鹤影”',
          rating: 5
        },
        {
          user: '玛嘉烈玛嘉烈   ',
          date: ' 2013-06-28 21:18:59',
          content: '“为官的，家业凋零；富贵的，金银散尽；有恩的，死里逃生；无情的，分明报应。欠命的，命已还；欠泪的，泪已尽。冤冤相报实非轻，分离聚合皆前定。欲知命短问前生，老来富贵也真侥幸。看破的，遁入空门；痴迷的，枉送了性命。好一似食尽鸟投林，落了片白茫茫大地真干净！”第四遍，还是会被震撼到。',
          rating: 4
        },
        {
          user: '夏言   ',
          date: ' 2013-01-03 18:32:52',
          content: '读了三十多遍，文学史上的断臂维纳斯',
          rating: 5
        },
        {
          user: '沉歌   ',
          date: ' 2008-01-24 15:32:48',
          content: '满纸荒唐言，一把辛酸泪。都云作者痴，谁解其中味。',
          rating: 5
        },
        {
          user: '暗夜大佬   ',
          date: ' 2011-11-19 23:33:55',
          content: '后四十回的续作者明显不如曹雪芹那样有才华，情节安排上也较为匆忙，完全没有曹雪芹那样的气定神闲。总是在想，为什么这样一部佳作，会遗失了后面的章节？难见到全本的曹雪芹所著《红楼梦》，终归是一件遗憾。',
          rating: 4
        },
        {
          user: '芦哲峰  ',
          date: '  2007-10-29 20:57:53',
          content: '真正的《红楼》，隐藏在每个人的内心深处，它就是我们的泪水人生；就是那“乱烘烘你方唱罢我登场，到头来，都是为他人作嫁衣裳”的荒谬；就是那“一朝春尽红颜老，花落人亡两不知”的无奈；就是那“因空见色，由色生情，传情入色，自色悟空”的轮回和缘起缘灭……从生到死，从空到空，每个人走过的，都将是“泪水”的一生，“情”的一生！最终也都将化为“食尽鸟投林，落了片白茫茫大地真干净”的无常。',
          rating: 5
        }
      ],
      holdingInfoTableShowStrCallNumber: true,
      authorOtherBooksTableShowStrCallNumber: true,
      loading: false,
      loading0: false,
      totalBookNum: 0,
      dangdangUrl: '',
      doubanUrl: '',
      imgUrl: 'https://next.sanyue100.com/covers/',
      holdingInfoList: [],
      searchForm: {},
      searchBooksForm: {},
      book: {},
      otherBooks: [],
      searchOtherBooksForm: {
        Page: 1,
        PerPage: 999
      },
      routerPath: ''
    }
  },
  computed: {
    // 根据书名动态返回评论列表
    comments () {
      if (this.book.StrPositiveTitle === '纳兰容若词传') {
        return this.commentsForBook1
      }
      if (this.book.StrPositiveTitle === '昆虫记') {
        return this.commentsForBook2
      }
      if (this.book.StrPositiveTitle === '骆驼祥子') {
        return this.commentsForBook3
      }
      if (this.book.StrPositiveTitle === '海底两万里') {
        return this.commentsForBook4
      }
      if (this.book.StrPositiveTitle === '朱自清散文精选') {
        return this.commentsForBook5
      }
      if (this.book.StrPositiveTitle === '在细雨中呼喊') {
        return this.commentsForBook6
      }
      if (this.book.StrPositiveTitle === '流浪地球') {
        return this.commentsForBook7
      }
      if (this.book.StrPositiveTitle === '我们的荆轲') {
        return this.commentsForBook8
      }
      if (this.book.StrPositiveTitle === '三国演义') {
        return this.commentsForBook9
      }
      if (this.book.StrPositiveTitle === '西游记') {
        return this.commentsForBook10
      }
      if (this.book.StrPositiveTitle === '红楼梦') {
        return this.commentsForBook11
      }
      // 如果没有匹配的书名，返回空列表
      return []
    }
  },
  created () {
    this.searchForm = Object.assign({}, JSON.parse(this.$route.query.res))
    this.book = Object.assign({}, JSON.parse(this.$route.query.book))
    // this.getImg(this.book)
    // searchForm的内容不要改动
    this.routerPath = this.$route.query.router
    this.getBooksByISBN()
    this.dangdangUrl = 'http://search.dangdang.com/?key=' + this.book.StrISBN + '\\&act=input'
    this.doubanUrl = 'https://search.douban.com/book/subject_search?search_text=' + this.book.StrISBN
    if (this.book.StrFirstResponsible !== 'NULL' && this.book.StrFirstResponsible !== '') {
      this.authorOtherBooks()
    }
  },
  methods: {
    getDouBanS () {
      if (this.book.StrPositiveTitle === '纳兰容若词传') {
        return 'ccccc'
      }
      return 'aaaaaa'
    },
    backToBookList () {
      this.$router.push({
        path: this.routerPath,
        query: { res: JSON.stringify(this.searchForm) }
      })
    },
    async getBooksByISBN () {
      this.loading0 = true
      this.searchBooksForm = Object.assign({}, this.searchForm)

      // if(this.searchBooksForm.StrOrganizationID !== undefined){
      //   this.searchBooksForm.StrOrganizationIDs = []
      //   this.searchBooksForm.StrOrganizationIDs[0] = this.searchBooksForm.StrOrganizationID
      // }

      this.searchBooksForm.StrOrganizationIDs = []
      this.searchBooksForm.StrOrganizationIDs[0] = localStorage.getItem('library') == null ? '' : localStorage.getItem('library')

      this.searchBooksForm.KeyName = 'StrISBN'
      this.searchBooksForm.StrKeywords = this.book.StrISBN
      this.searchBooksForm.Page = 1
      this.searchBooksForm.PerPage = this.book.IntNumberCopies
      this.searchBooksForm.Distinct = false

      const result = await getBooksByConditions(this.searchBooksForm)
      if (result.ok) {
        this.holdingInfoList = result.msg
        for (var i in this.holdingInfoList) {
          if (this.holdingInfoList[i].StrCallNumber === '') {
            this.holdingInfoTableShowStrCallNumber = false
            break
          }
        }
      } else {
        return this.$message(result.msg)
      }
      this.loading0 = false
    },
    async authorOtherBooks () {
      this.loading = true
      this.searchOtherBooksForm.StrFirstResponsible = this.book.StrFirstResponsible

      this.searchOtherBooksForm.StrOrganizationID = localStorage.getItem('library') == null ? '' : localStorage.getItem('library')
      // if(this.searchForm.StrOrganizationID !== undefined){
      //   this.searchOtherBooksForm.StrOrganizationID = this.searchForm.StrOrganizationID
      // }else if(this.searchForm.StrOrganizationIDs !== undefined){
      //   this.searchOtherBooksForm.StrOrganizationID = this.searchForm.StrOrganizationIDs[0]
      // }

      this.searchOtherBooksForm.CurrentStrISBN = this.book.StrISBN
      const result = await getAuthorOtherBooks(this.searchOtherBooksForm)
      if (result.ok) {
        this.totalBookNum = result.total
        this.otherBooks = result.msg
        for (var i in this.otherBooks) {
          if (this.otherBooks[i].StrCallNumber === '') {
            this.authorOtherBooksTableShowStrCallNumber = false
            break
          }
        }
        // console.log(this.otherBooks)
      } else {
        return this.$message(result.msg)
      }
      this.loading = false
    },
    // 每页显示条数更改
    handleSizeChange (newSize) {
      this.searchOtherBooksForm.PerPage = newSize
      this.authorOtherBooks()
    },
    // 监听页码值改变的事件
    handleCurrentChange (newPage) {
      this.searchOtherBooksForm.Page = newPage
      this.authorOtherBooks()
    },
    getRandomBorrowDate () {
      // 获取当前日期
      const currentDate = new Date()
      // 随机生成0到30之间的天数
      const randomDays = Math.floor(Math.random() * 30)
      // 计算30天前的日期
      currentDate.setDate(currentDate.getDate() - randomDays)
      // 格式化日期为 YYYY-MM-DD
      const year = currentDate.getFullYear()
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
      const day = currentDate.getDate().toString().padStart(2, '0')
      return `${year}-${month}-${day}`
    },
    // 获取应还日期（借出日期后加30天）
    getDueDate () {
      // 获取当前日期
      const currentDate = new Date()
      // 假设借出日期是30天前的日期
      currentDate.setDate(currentDate.getDate() + 20)
      // 格式化日期为 YYYY-MM-DD
      const year = currentDate.getFullYear()
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
      const day = currentDate.getDate().toString().padStart(2, '0')
      return `${year}-${month}-${day}`
    }
  }
}
</script>

<style lang="less" scoped>
.el-card {
  margin-bottom: 20px;
}
.backTop {
  margin-bottom: 20px;
  padding: 50px;
}
.bookInfo {
  display: flex;
  .bookTitle {
    font-size: 24px;
    margin-bottom: 20px;
  }
  margin-bottom: 20px;
}
.bookImg {
  margin: 0 40px;
  width: 150px;
  height: 150px;
  overflow: hidden;
  padding: 20px 0 ;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.infoTableItem {
  display: flex;
}
.infoTableItemTitle {
  width: 200px;
  margin-bottom: 4px;
}
.main_title {
  font-size: 20px;
  margin-bottom: 10px;
}
.bookBuyUrl {
  a{
    color: black;
    text-decoration: none;
  }
}
.bookBuyUrl a:hover {
  color: #3f82c4;
}
#maxBox{
  width:80%;
  margin:0px auto;
}

.bookReview {
  margin: 0px;
}

.main_title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.comment-item {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.comment-header {
  font-size: 14px;
  color: #888;
}

.comment-user {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.comment-date {
  margin-left: 10px;
}

.comment-content {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
}

.star-rating {
  margin-left: 10px;
  color: gold; /* 星星的颜色 */
  font-size: 16px; /* 统一星星大小 */
}

.el-icon-star-on, .el-icon-star-off {
  font-size: 16px;
}

.el-icon-star-on {
  color: gold;
}

.el-icon-star-off {
  color: #d3d3d3;
  font-size: 13px
}
.comment-list {
  max-height: 400px; /* 设置最大高度 */
  overflow-y: auto; /* 开启垂直滚动 */
  padding-right: 10px; /* 防止滚动条遮挡内容 */
}
</style>
