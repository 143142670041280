<template>
  <el-container>
    <el-header style="
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1000; /* 设置一个较高的z-index以确保其在其他元素之上 */
        background-color: #378ee8; /* 确保背景颜色与原来一致，覆盖整个屏幕宽度 */
      ">
        <el-menu  ref="head" router :default-active="$router.path" class="el-menu-demo head" mode="horizontal" text-color="#fff"  active-text-color="#fff" background-color= "#378ee8"
                  style="width: 100%;" >
          <el-menu-item index="/index">检索首页</el-menu-item>
          <!-- <el-menu-item index="/browse">分类浏览</el-menu-item> -->
          <el-menu-item index="/newBook">新书速递</el-menu-item>
          <el-menu-item index="/hotBook">热门图书</el-menu-item>
          <el-menu-item index="/">我的图书馆</el-menu-item>
<!--          <el-menu-item index="/hotBook">课外必读</el-menu-item>-->
        </el-menu>

    </el-header>
    <el-main style="position: relative">
      <!-- <el-card class="contentCard"> -->
        <router-view></router-view>
      <!-- </el-card> -->
    </el-main>

    <el-footer style="padding-top: 8%; width: 100% ;font-size: 0.5rem">
      ©2009-2021 杭州三阅信息技术有限公司
    </el-footer>
</el-container>
</template>

<script>
import { getAllLibs } from '@/api'

export default {
  data () {
    return {
      dialogVisible: false,
      libraryAddresses: [],
      organizationID: '',
      showClose: false,
      settingIsIcon: true,
      libName: ''
    }
  },
  created () {
    // var path = window.location.href
    // this.activePath = path.substring(path.lastIndexOf('/'))
    // this.activePath = this.activePath.split('?')[0]

    this.activePath = this.$router.currentRoute.path
    this.$router.path = this.activePath
  },
  mounted () {
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
  .el-menu-item{
    border-bottom: none !important;
    font-size: 1rem !important;
  }
  .el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
      background-color: rgba(0, 0, 0,0.2) !important;
  }
  .el-menu--horizontal>.el-menu-item.is-active {
    border-bottom: none !important;
  }
  .head{
    height: 100%;
    border: none;
    display: flex;
    justify-content: space-around;
  }
  #settingdiv{
    position: absolute;
    left: 0;
    bottom: 0;
  }
  /deep/ #menudiv>.el-menu.el-menu--horizontal {
    border-bottom: none !important;
  }
  #settingdiv>.el-button--text{
    color: #606266;
  }
  #settingdiv>.el-button{
    font-size: 18px ;
  }
</style>
